import React, {useState, useEffect} from 'react';
import { Grid, Typography, Button, Divider } from '@mui/material';
import dayjs from 'dayjs';
import AlertItem from '../../Components/AlertItem';
import { getOfficerElectionSummary, completeOfficerElection } from '../../endpoints/members';
import { useApiGet, useApiSend } from '../../utils/httpClient';


const ElectionSummary = ({id, handleBack, formStep, reset}) => {
    const [showAlert, setShowAlert] = useState(false);
    const [alertSeverity, setAlertSeverity] = useState('success');
    const [alertMessage, setAlertMessage] = useState('');
    const [keepOpen, setKeepOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    
    const { data, refetch, isFetching } = useApiGet(
        ['election-summary', id],
        getOfficerElectionSummary,
        {
            enabled: id ? true : false,
        },
        { id }
    );

    const { mutate, isPending } = useApiSend(
        completeOfficerElection,
        (data) => {
            refetch();
            setAlertSeverity('success');
            setAlertMessage('Election completed successfully');
            setShowAlert(true);
            setKeepOpen(false);
            // wait for the alert to close before resetting the form
            setTimeout(() => {
                reset();
                setIsLoading(false);
            }
            , 2000);
        },
        (error) => {
            setAlertSeverity('error');
            setAlertMessage('Error completing election');
            setShowAlert(true);
            setKeepOpen(true);
            setIsLoading(false);
        }
    );

    useEffect(() => {
        if (formStep === 3) {
            refetch();
        }
    }, [formStep, refetch]);

    const handleComplete = () => {
        setIsLoading(true);
        mutate({id: id});
    }
  
    return (
      <Grid container>
        <Grid item xs={12} textAlign={'center'} paddingTop={2}>
          <Typography variant="h4">Chapter Summary</Typography>
        </Grid>
        {isFetching && 
          <Grid item xs={12} textAlign={'center'} paddingTop={2}>
            <Typography variant="h5">Loading...</Typography>
          </Grid>}
          {data && (
            <Grid container item xs={12}>
                <Grid item xs={12} padding>
                    <Typography variant="body2">
                        Meeting Format: {data.local_chapter_type_name}
                    </Typography>
                    <Typography variant="body2">
                        Officers can serve multiple years: {data.two_year_term ? 'Yes' : 'No'}
                    </Typography>
                    <Typography variant="body2">
                        Chapter Highlights: {data.chapter_highlights ? data.chapter_highlights : 'None'}
                    </Typography>
                </Grid>
                {data.meeting_times.length > 0 && (
                <Grid item xs={12}>
                    <Grid item xs={12} padding>
                        <Divider>
                            <Typography variant='overline'>Meeting Times</Typography>
                        </Divider>
                    </Grid>
                    {data.meeting_times.map((meeting) => (
                        <Grid item container padding={1}>
                            <Grid item xs={12}>
                              <Typography variant="body2" key={meeting.id}>
                                {meeting.start_month} - {meeting.end_month} - {meeting.frequency_timing} {meeting.day_of_week} {meeting.frequency} @ {meeting.time_of_day} {meeting.midday}
                              </Typography>
                            </Grid>
                        </Grid>
                    ))}
                </Grid>)}
                <Grid item xs={12} padding>
                    <Divider>
                        <Typography variant='overline'>Officers</Typography>
                    </Divider>
                </Grid>
                <Grid container item xs={12} padding>
                    {data.officers.map((officer, index) => (
                        <Grid container item xs={12} sm={6} key={index} padding>
                            <Grid item xs={12}>
                                <Typography variant='body2' fontWeight={'bold'}>{officer.position}</Typography>
                                <Typography variant='body2'>{officer.name ? officer.name : 'Vacant'}</Typography>
                                <Typography variant='body2'>{officer.start_date ? dayjs(officer.start_date).format('MMM DD, YYYY') : ''}</Typography>
                            </Grid>
                        </Grid>))}
                </Grid>
                <Grid item xs={12} padding>
                    <Divider/>
                </Grid>
                <Grid item container xs={12} padding  paddingBottom={5}>
                    <Grid item xs={12}>
                        {showAlert && (
                            <AlertItem
                                severity={alertSeverity}
                                description={alertMessage}
                                keepOpen={keepOpen}
                                onClose={() => setShowAlert(false)}/>
                        )}
                        </Grid>
                    <Grid item xs={12} sm={6} padding textAlign={'right'}>
                        <Button
                            color='black'
                            variant='contained'
                            size='small'
                            onClick={handleBack}
                            disabled={isPending || isLoading}
                            padding={2}>
                            <Typography fontWeight={'bold'} variant='body2'>Back: Officers</Typography>
                        </Button>
                        </Grid>
                        
                        <Grid item xs={12} sm={6} padding>
                            <Button
                                color='primary'
                                size='small'
                                variant='contained'
                                onClick={() => handleComplete()}
                                disabled={isPending || isLoading}
                            >
                                <Typography fontWeight={'bold'} variant='body2'>
                                    {data.status_id === 'e4f360df636e4cbdbe6d221e637a5229' ? 'Save' : 'Complete'}
                                </Typography>
                            </Button>
                    </Grid>
                </Grid>
            </Grid>)}
      </Grid>
    );
  };

export default ElectionSummary;