import React, {useState} from 'react';
import { Grid, Typography, Button, Tooltip, Divider, IconButton } from '@mui/material';
import UserSelectModal from '../../Components/UserSearchModal';
import { Edit } from '@mui/icons-material';
import DateTimePicker from '../../Components/DateTimePicker';
import dayjs from 'dayjs';
import { getOfficerElectionOfficiers, addOfficierElectionOfficer } from '../../endpoints/members';
import { useApiGet, useApiSend } from '../../utils/httpClient';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

const required_officers = ['Local Chapter President', 'Local Chapter Treasurer', 'Local Chapter Corresponding Secretary']

const OfficersVerification = ({id, handleNext, handleBack}) => {
    const [officerSelected, setOfficerSelected] = useState(null);
    const [officerModalOpen, setOfficerModalOpen] = useState(false);
    const [newDate, setNewDate] = useState(null);

    const { data, isFetching, refetch } = useApiGet(
        ['election-officer-info', id],
        getOfficerElectionOfficiers,
        {
        enabled: id ? true : false,
        },
        { id }
    );
    
    const { mutate: createOfficer, isPending } = useApiSend(
        addOfficierElectionOfficer,
        (data) => {
            refetch();
        },
        (error) => {
            console.log(error);
        }
        );


    const reinstallOfficer = (officer) => {
        createOfficer({id, reinstall: true, position_id: officer.id});
        setOfficerSelected(officer.id);
    }
    
    const handleSetOfficer = (officer) => {
        setOfficerModalOpen(false);
        createOfficer({id, position_id: officerSelected, new_user_id: officer});
        setNewDate(officer.new_start_date)
    }

    const isDisabled = () => {
        let disabled = false;
        required_officers.forEach((position) => {
            let officer = data.officers.find((officer) => officer.position === position);
            if (!officer) {
                disabled = true;
            } else if (officer.current_year === null) {
                disabled = true;
            }
        });
        return disabled;
    }

    const handleEditOfficer = (officer) => {
        setOfficerSelected(officer.id);
    }

    const handleDateChange = (date) => {
        setNewDate(date);
    }

    const handleSave = () => {
        let dateToSend = newDate ? newDate.format('YYYY-MM-DD') : dayjs().format('YYYY-MM-DD');
        createOfficer({id, position_id: officerSelected, new_start_date: dateToSend});
        setOfficerSelected(null);
    }

    const handleDate = (officerId, position) => {
        if (position.current_year && position.current_year.start_date) {
            if (officerSelected && officerId === officerSelected) {
                return (
                    <DateTimePicker
                        date={dayjs(position.current_year.start_date)}
                        label={'Installation Date'}
                        minDate={dayjs('2025-03-01')}
                        maxDate={dayjs('2026-03-01')}
                        disableFuture={false}
                        handleErrorUpdate={(update) => console.log('error update', update)}
                        handleDateChange={handleDateChange} />
                )
            } else {
                return (
                    <Typography variant='body2'>Installation Date: {dayjs(position.current_year.start_date).utc().format('MMM DD, YYYY')}</Typography>
                )
            }
        } else {
            return (
                <Typography variant='body2'>Installation Date: N/A</Typography>
            )
        }
    }

    const handleOfficier = (officer) => {
        if (officerSelected && officerSelected === officer.id) {
            return (
                <Typography variant='body2'>
                    2025: {officer.current_year ? officer.current_year.name : 'Vacant'}
                    <IconButton color='blue' onClick={() => setOfficerModalOpen(true)}>
                        <Edit/>
                    </IconButton>
                </Typography>
            )
        } else {
            return (
                <Typography variant='body2'>
                    2025: {officer.current_year ? officer.current_year.name : 'Vacant'}
                </Typography>
            )
        }
    }


    return (
      <Grid container paddingBottom={5}>
        <Grid item xs={12} textAlign={'center'} paddingTop={2}>
          <Typography variant="h4">Officers Verification</Typography>
        </Grid>
        {(isFetching || isPending) && (
            <Grid item xs={12} textAlign={'center'}>
                <Typography variant="h5">Loading...</Typography>
            </Grid>)}
          {data && !isFetching && (
            <Grid item container xs={12}>
                <Grid item xs={12} padding>
                    <Divider>
                        <Typography variant='overline'>Chapter Officers</Typography>
                    </Divider>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant='body1'>
                        Please review and update the following officer information for your chapter.
                    </Typography>
                    {data.officers.map((officer, index) => (
                        <Grid container item xs={12} key={index} padding>
                            <Grid item xs={12}>
                                <Typography variant='body2' fontWeight={'bold'}>
                                    {officer.position}{required_officers.includes(officer.position) ? ' *' : ''}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Button
                                    variant='contained'
                                    size='small'
                                    onClick={() => reinstallOfficer(officer)}
                                    disabled={officer.last_year.name ? false : true}>
                                    <Typography
                                        fontWeight={'bold'}
                                        variant='body2'>
                                        Re-elected
                                    </Typography>
                                </Button>
                                <Typography variant='body2'>Current: {officer.last_year.name ? officer.last_year.name : 'Vacant'}</Typography>
                                <Typography variant='body2'>
                                    Installation Date: {officer.last_year.start_date ? dayjs(officer.last_year.start_date).utc().format('MMM DD, YYYY')
                                                                                     : 'N/A'}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                {officerSelected && officerSelected === officer.id ? (
                                    <div>
                                    <Button variant='contained' size='small' color='green' onClick={() => handleSave()}>
                                        <Typography fontWeight={'bold'} variant='body2'>Save</Typography>
                                    </Button>
                                    <Button variant='contained' size='small' color='warning' onClick={() => setOfficerSelected(null)}>
                                        <Typography fontWeight={'bold'} variant='body2'>Cancel</Typography>
                                    </Button>
                                    </div>
                                ) : (
                                    <Button variant='contained' size='small' onClick={() => handleEditOfficer(officer)}>
                                        <Typography fontWeight={'bold'} variant='body2'>Edit</Typography>
                                    </Button>
                                )}
                                {handleOfficier(officer)}
                                {handleDate(officer.id, officer)}
                                
                            </Grid>
                        </Grid>))}
                </Grid>
                <Grid item xs={12} padding>
                    <Divider/>
                </Grid>
                <Grid item container xs={12} padding>
                    <Grid item xs={12} sm={6} padding textAlign={'right'}>
                    <Button
                        color='black'
                        variant='contained'
                        size='small'
                        onClick={handleBack}
                        padding={2}
                        >
                            <Typography fontWeight={'bold'} variant='body2'>Back: Meeting Info</Typography>
                        </Button>
                        </Grid>
                        <Grid item xs={12} sm={6} padding>
                        <Tooltip placement={'top'} title={
                            isDisabled() ? 'Required Positions are the President, Corresponding Secretary, and Treasurer' : ''
                        }>
                            <span>
                                <Button
                                    color='primary'
                                    size='small'
                                    variant='contained'
                                    onClick={handleNext}
                                    disabled={isDisabled()}
                                >
                                    <Typography fontWeight={'bold'} variant='body2'>Next: Summary / Complete</Typography>
                                </Button>
                            </span>
                        </Tooltip>
                    </Grid>
                </Grid>
            </Grid>
          )}
          <UserSelectModal
            modalOpen={officerModalOpen}
            setUser={handleSetOfficer}
            setModalOpen={setOfficerModalOpen}
            chapter={id} />
      </Grid>
    );
  };

export default OfficersVerification;